import { Accordion, AccordionControlProps, Flex, Text, createStyles, useMantineTheme } from "@mantine/core";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import { useContext } from "react";
import { AppInfoContext } from "../../../../components/contexts/AppInfoContext";
import { Echelon, ForcePackageData, Optional } from "../../../../types/filters";
import styled from "@emotion/styled/macro";
import { findHighestAgentId } from "../../functions/findHighestAgentId";
import _ from "lodash";
import { findHighestEchelonId } from "../../functions/findHighestEchelonId";
import { updateEchelonIds } from "../../functions/updateEchelonIds";
import { updateEchelonAgentIds } from "../../functions/updateEchelonAgentIds";

interface AdditionalProps {
	echelon: Echelon;
	isTopLevel: boolean;
	settings: Optional<ForcePackageData, "id">;
	setSetting: (value: Echelon | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
}

type IAccordionsControl = AccordionControlProps & AdditionalProps;

const NestedAccordionsControl: React.FC<IAccordionsControl> = (props: IAccordionsControl) => {
	const { classes } = useStyles();
	const { isSuperuser } = useContext(AppInfoContext);
	const { setSetting, isTopLevel, echelon, ...nativeProps } = props;
	const theme = useMantineTheme();

	return (
		<AccordionControl
			{...nativeProps}
			sx={{ display: "flex", alignItems: "center" }}
			className={isTopLevel ? classes.topLevelAccordionControl : ""}
			autoCorrect="false"
			spellCheck="false"
			value={echelon.name}
		>
			<Text className={classes.accordionText}>{echelon.name}</Text>
			{!isTopLevel && isSuperuser ? (
				<Flex className={classes.buttonsContainer}>
					<IconWrapper
						title="Duplicate"
						onClick={(e) => {
							e.stopPropagation();
							let highestAgentId = findHighestAgentId(props.settings.fp.echelons);
							let highestEchelonId = findHighestEchelonId(props.settings.fp.echelons);
							const copiedEchelon = _.cloneDeep(echelon);
							const updatedEchelon = updateEchelonIds(
								updateEchelonAgentIds(copiedEchelon, highestAgentId)[0],
								highestEchelonId
							)[0];
							setSetting(updatedEchelon, true);
						}}
					>
						<IconCopy size="1.1rem" color={theme.colorScheme === "dark" ? "gray" : theme.colors.light[2]}></IconCopy>
					</IconWrapper>
					<IconWrapper
						title="Remove"
						onClick={(e) => {
							e.stopPropagation();
							setSetting(null, false);
						}}
					>
						<IconTrash size="1.1rem" color={theme.colorScheme === "dark" ? "gray" : theme.colors.light[2]}></IconTrash>
					</IconWrapper>
				</Flex>
			) : null}
		</AccordionControl>
	);
};

const useStyles = createStyles((theme) => ({
	buttonsContainer: {
		display: "inline-flex !important",
		borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.light[5]}`,
		backgroundColor: "inherit",
		height: "100%",
		paddingRight: "0.5rem",
	},

	topLevelAccordionControl: {
		backgroundColor: `${theme.colors.appMainColor[5]} !important`,
		color: `${theme.colors.accentRed[0]} !important`,

		":hover": {
			backgroundColor: `${theme.colors.appMainColor[6]} !important`,
			cursor: "pointer !important",
		},
	},

	accordionText: {
		width: "fit-content",
		display: "inline",
		border: `0.125rem solid transparent`,
		padding: ".1rem 0.5rem",
		borderRadius: "0.25rem",

		"&:focus-visible": {
			outline: "none",
			border: `0.125rem solid ${theme.colors.accentRed[6]}`,
		},

		"&:hover": theme.fn.hover({
			cursor: "text",
		}),
	},
}));

const AccordionControl = styled(Accordion.Control)`
	border-bottom: none;
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1])};
	text-align: left;
	justify-content: space-between;
	cursor: pointer;

	& .mantine-Accordion-label {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	& .mantine-Accordion-chevron {
		margin-left: 1rem;
	}
`;

const IconWrapper = styled(Flex)`
	color: white;
	border-radius: 0.25rem;
	height: 1.75rem;
	min-height: 1.75rem;
	width: 1.75rem;
	min-width: 1.75rem;
	border: 0.0625rem solid transparent;
	align-items: center;
	justify-content: center;

	& :active {
		margin-top: 0.0625rem;
	}
`;

export default NestedAccordionsControl;
